// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  login: `登录`,
  logout: `登出`,
  register: `注册`,
  already_have_account: `已经有账户？`,
  my_profile: `我的资料`,
  basic_information: `基本信息`,
  edit: `编辑`,
  user_id: `用户ID`,
  username: `用户名`,
  name: '姓名',
  phone_number: '电话号码',
  confirm_password: '确认密码',
  password_must_match: '密码必须匹配',
  account_type: `账户类型`,
  status: `状态`,
  currency: `货币`,
  created_time: `创建时间`,
  last_login: `最后登录`,
  prefix: `前缀`,
  wallet_type: `钱包类型`,
  product_information: `产品信息`,
  platform: `平台`,
  supported_currency: `支持的货币`,
  my_pt_allow: `我的PT允许`,
  bet_limit: `投注限额`,
  table: `桌面`,
  virtual: `虚拟`,
  member_tree: `会员树`,
  real: `真实`,
  test: `测试`,
  active: `活跃`,
  suspended: `已暂停`,
  locked: `已锁定`,
  submit: `提交`,
  export: `导出`,
  view: `查看`,
  login_failure: `登录失败`,
  password_lock: `密码锁`,
  input_x: `输入{{x}}`,
  sub_account: `子账户`,
  add_sub_account: `添加子账户`,
  win_loss_summary: `赢/亏总结`,
  date_time: `日期和时间`,
  time_zone: `时区`,
  type: `类型`,
  location: `位置`,
  last_month: `上个月`,
  this_month: `本月`,
  last_week: `上周`,
  this_week: `本周`,
  yesterday: `昨天`,
  today: `今天`,
  view_all: `查看全部`,
  display_columns: `显示列`,
  summary: `总结`,
  valid_turnover: `有效投注额`,
  total_bet: `总投注`,
  bet_amount: `投注金额`,
  player: `玩家`,
  player_win_loss: `玩家赢/亏`,
  adjustment: `调整`,
  total_pl: `总盈亏`,
  margin: `利润`,
  pt_win_loss: `PT赢/亏`,
  agent: `代理`,
  company: `公司`,
  grand_total_x: `总计（{{x}}）`,
  start_date: `开始日期`,
  end_date: `结束日期`,
  clear: `清除`,
  bet_count: `投注次数`,
  total: `总计`,
  all: `全部`,
  adjustment_detail: `调整详情`,
  adjustment_type: `调整类型`,
  game_type: `游戏类型`,
  adjustment_types: `调整类型`,
  items: `项目`,
  account_statement: `账户报表`,
  player_id: `玩家ID`,
  debit: `借记`,
  credit: `贷记`,
  before_balance: `调整前余额`,
  after_balance: `调整后余额`,
  request_id: `请求ID`,
  tip_report: `小费报告`,
  no_data: `无数据`,
  total_tip: `总小费`,
  products: `产品`,
  push_message: `推送消息`,
  add_product: `添加产品`,
  product_name: '产品名称',
  search: '搜索',
  is_active: '是否活跃',
  permission: '权限',
  x_is_required: '{{x}}是必需的',
  please_login_to_complete: '请登录以完成加入群组或',
  click_to_cancel: '点击这里取消',
  scan_qr: '扫描二维码',
  password: '密码',
  min_char_is_x: '最少字符数为{{x}}',
  camera_access_denied: '相机访问已被拒绝。请检查您的浏览器设置。',
  camera_not_available: '此设备上无法访问相机。',
  dashboard: '仪表盘',
  points: '积分',
  lifetime: '终身',
  claim_chips: '领取筹码',
  would_like_to_claim_x_chips: '您现在要领取{{x}}筹码吗？',
  chips_rejected: '筹码已拒绝！',
  chips_added: '筹码已添加！',
  accept: '接受',
  reject: '拒绝',
  win_lose: '赢/输',
  my_groups: '我的群组',
  select_country_code: '选择国家代码',
  yes: '是',
  no: '否',
  my_transactions: '我的交易',
  event_ended: '活动已结束',
  country_code: '国家/地区代码',
  last_updated: '最后更新',
  edit_profile: '编辑资料',
  accepted: '已接受',
  rejected: '已拒绝',
  would_like_to_claim_chips: '您想领取您的筹码吗？',
  x_success: '{{x}} 成功!',
  chips_available: '可用筹码',
  no_active_group: '没有活跃的群组',
  seems_no_active_group: '看起来您没有活跃的群组，您想扫描一个群组二维码吗？',
  already_in_group: '您已经有一个活跃的群组！如果您有一个活跃的群组，您不能加入另一个群组',
  total_win_lose: '总胜负',
  overall_win_lose_turnover: '整体输赢及营业额',
  view_overall_win_lose: '查看整体输赢',
  turnover: '营业额',
  home: '家',
  group: '团体',
  current_points: '当前积分',
  lifetime_points: '终身积分',
  insurance: '保险',
  request_otp: '请求 OTP',
  invalid_x: '无效的 {{x}}',
  resend_otp_in_x: '{{x}} 后重新发送 OTP',
  resend_otp: '重新发送 OTP',
  otp: 'OTP',
  otp_code: 'OTP 代码',
  otp_sent_please_input: 'OTP 已发送！请输入代码以继续',
  forgot_password: '忘记密码',
  please_enter_phonenumber_associated: '请输入与您的账户关联的电话号码，我们将发送验证码以验证并重设您的密码',
  back: '返回',
  no_group_or_agent: '未选择任何团体或代理！',
  verified_by_play_protect: '已通过 Play Protect 验证',
  reviews: '26K 条评论',
  reviews_value: '4M+',
  rated_for: '适合 18+',
  install: '安装',
  add_to_wishlist: '加入愿望清单',
  about_this_app: '关于此应用',
  app_description_1:
    'TOP1ABC 是您管理和监控与赌场相关活动的终极助手。专为玩家、管理者和爱好者设计，这款应用提供以下便捷功能：',
  feature_1: '追踪场馆：轻松查看和组织您关联的赌场场馆，包括详细的位置信息和关键统计数据。',
  feature_2: '管理住宿：集中管理与赌场旅行相关的酒店预订、地址和费用。',
  feature_3: '筹码分配：实时监控分配到各场馆的筹码，为您提供游戏余额的清晰视图。',
  app_description_2:
    '凭借其简洁、用户友好的界面和强大的功能，TOP1ABC 确保您始终掌握信息并保持条理，让您尽情享受游戏乐趣。',
  key_features: '主要功能：',
  key_feature_1: '查看详细的赌场场馆档案。',
  key_feature_2: '高效管理和跟踪住宿信息。',
  key_feature_3: '实时筹码分配监控。',
  key_feature_4: '可定制的仪表板，以满足您的偏好。',
  app_download: '应用下载',
  download_message: '准备好轻松自信地提升您的赌场体验吧！',
  share: '分享',
  download: '下载',
};

export default cn;
