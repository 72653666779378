// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_AUTH = '/auth';
const ROOT_MY_PROFILE = '/dashboard';
const ROOT_MY_TRANSACTIONS = '/my-transactions';
const ROOT_ACCOMODATION = '/accomodation';
const ROOT_WINLOSS = '/win-loss';
const ROOT_REWARDS = '/rewards';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, '/login'),
  register: path(ROOT_AUTH, '/register'),
  resetPassword: path(ROOT_AUTH, '/reset-password'),
  newPassword: path(ROOT_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_MY_PROFILE = {
  root: ROOT_MY_PROFILE,
};

export const PATH_MY_TRANSACTIONS = {
  root: ROOT_MY_TRANSACTIONS,
};

export const PATH_ACCOMODATION = {
  root: ROOT_ACCOMODATION,
};

export const PATH_WINLOSS = {
  root: ROOT_WINLOSS,
};

export const PATH_REWARDS = {
  root: ROOT_REWARDS,
};
