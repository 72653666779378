export function toSnakeCase(str) {
  return str.replace(/\s+/g, '_').toLowerCase();
}

export function isSnakeCase(str) {
  return /^[a-z]+(_[a-z]+)*$/.test(str);
}

export function getInitials(name) {
  const words = name.split(' ');

  const initials = words.map((word) => word.charAt(0).toUpperCase());

  return initials.join('');
}

export function fToSnake(inputString) {
  if (!inputString) return '';
  try {
    // Convert the string to lowercase
    const lowercaseString = inputString.toLowerCase();

    // Replace spaces, symbols, and consecutive symbols with underscores
    const formattedString = lowercaseString.replace(/[\s,!@#$%^&*()\-_+=<>.?/|\\]+/g, '_');

    return formattedString;
  } catch (error) {
    return inputString;
  }
}

export function isValidUrl(string) {
  const regex =
    /^(https?:\/\/)?((localhost|(\d{1,3}\.){3}\d{1,3}|(\[[0-9a-fA-F:]+\]))(:\d+)?)([/\w.-]*)*\/?(\?.*)?(#.*)?$/;
  return regex.test(string);
}