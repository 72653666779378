// routes
import { PATH_MY_PROFILE, PATH_MY_TRANSACTIONS } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [
      // MY PROFILE
      // ----------------------------------------------------------------------
      {
        title: 'dashboard',
        path: PATH_MY_PROFILE.root,
        icon: subMenuIcon('ph:gauge'),
      },

      // My Transaction
      // ----------------------------------------------------------------------
      {
        title: 'my transactions',
        path: PATH_MY_TRANSACTIONS.root,
        icon: subMenuIcon('icon-park-solid:log'),
      },

      // FLIGHT AND ACCOMODATION
      // ----------------------------------------------------------------------
      // {
      //   title: 'flight and accomodation',
      //   path: PATH_ACCOMODATION.root,
      //   icon: subMenuIcon('ri:plane-fill'),
      // },

      // REPORTS
      // ----------------------------------------------------------------------
      // {
      //   title: 'win/loss report',
      //   path: PATH_WINLOSS.root,
      //   icon: subMenuIcon('iconoir:reports-solid'),
      // },

      // REWARD
      // ----------------------------------------------------------------------
      // {
      //   title: 'rewards',
      //   path: PATH_REWARDS.root,
      //   icon: subMenuIcon('mdi:gift'),
      // },
    ],
  },
];

export default navConfig;
