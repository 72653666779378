import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { countries } from '../_mock';
import Iconify from './Iconify';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_SELECTIONS } from '../config';
import useLocales from '../hooks/useLocales';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const PhonenumberTextField = ({ onChange, value, showCountrySelections = true, ...others }) => {
  const [country, setCountry] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const { translate } = useLocales();

  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  useEffect(() => {
    const _countryCode = process.env.REACT_APP_COUNTRY_CODE;

    const _country = getCountryCodeObj(_countryCode);

    if (_country) setCountry(_country);
  }, []);

  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, country?.code);

      if (phoneNumber) {
        const _country = countries.find((c) => c.phone === phoneNumber.countryCallingCode);
        if (_country && _country.code !== country?.code) {
          setCountry(_country);
        }
        setTextFieldValue(phoneNumber.nationalNumber);
      } else {
        setTextFieldValue(value);
      }
    } else {
      setTextFieldValue(value);
    }
  }, [value, country]);

  const handleCountryChange = (country) => {
    setCountry(country);
    setOpenCodeSelect(false);
  };

  const handleTextFieldChange = (event) => {
    const phone = event.target.value;
    const phoneNumber = parsePhoneNumberFromString(phone, country?.code);

    if (phoneNumber) {
      onChange(phoneNumber.number);
    } else {
      onChange(phone);
    }
  };

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  return (
    <>
      <TextField
        value={textFieldValue}
        sx={{ position: 'relative', color: 'grey !important' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ position: 'relative', color: 'grey' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleOpenMenu}
                endIcon={<Iconify icon={'mingcute:down-fill'} />}
                sx={{ mr: 0.5 }}
                tabIndex={-1}
              >
                {country?.code}
              </Button>
              +{country?.phone}
            </InputAdornment>
          ),
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
      >
        <CardContent>
          <Autocomplete
            // disablePortal
            fullWidth
            value={country}
            options={
              DEFAULT_COUNTRY_SELECTIONS && DEFAULT_COUNTRY_SELECTIONS?.length
                ? countries.filter((x) => DEFAULT_COUNTRY_SELECTIONS.includes(x?.code))
                : countries
            }
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCountryChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} label={translate('country_code')} />}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  selectCode: PropTypes.func,
  selectAreaCode: PropTypes.func,
  placeholder: PropTypes.string,
};

export default PhonenumberTextField;
